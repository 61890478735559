<template>
  <div>
    <navbar />
    <main class="profile-page">
      <section class="relative block h-500-px">
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="heroDivStyle"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
        </div>
        <div
          class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-blueGray-200 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
      </section>
      <section class="relative py-16 bg-blueGray-200">
        <div class="container mx-auto px-4">
          <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
            <div class="px-6 text-center mt-10">
              <div v-if="!store.userMetadata">
                <button
                  @click="loginOrSignup(true)"
                  class="bg-white text-blueGray-700 active:bg-blueGray-50 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150"
                  type="button"
                >
             Login or Sign up
            </button>
              </div>
              <div v-if="store.userMetadata" class="flex flex-wrap justify-center">
                <div
                  class="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center"
                >
                  <div class="relative">
                    <img
                      alt="..."
                      :src="profileImgSrc"
                      class="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                    />
                  </div>
                </div>
               </div>
              <div class="text-center mt-12">
                <h3
                  class="text-4xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2"
                >
                  {{ name }}
                </h3>               
              </div>
                <div v-if="store.userMetadata" class="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div class="flex flex-wrap justify-center">
                    <div v-if="purchases" class="w-full lg:w-9/12 px-4">
                      <h3 class="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">Purchases</h3>                      

                      <div class="container px-4 mx-auto">
                        <div class="flex flex-wrap">
                          <div v-if="isTransferPending" class="w-full px-4 flex-1">
                            <div>
                              <img
                                alt="Pending"
                                class="max-w-full rounded-lg"
                                :src="progress"                                
                              />                             
                            </div>
                            <span><i>Transfer pending...<br/> Page will refresh shortly.</i><!--&nbsp;<a :href="purchase.eventData.item.tokenUrl" target="_blank" ><i class="fa fa-link"></i></a>--></span> 
                          </div>
                          <div v-for="purchase in this.purchases" v-bind:key="purchase.id" class="w-full px-4 flex-1">
                            <div>
                              <img
                                v-if="!purchase.eventData.item.properties.animationUrl"
                                alt="..."
                                class="max-w-full rounded-lg"
                                :src="purchase.eventData.item.nftData.metadata.image"
                              />
                              <video v-if="purchase.eventData.item.properties.animationUrl" preload="auto" loop autoplay muted style="background-color: white;">
                                <source :src="purchase.eventData.item.properties.animationUrl">
                                Your browser does not support the video tag.
                              </video>
                              <span><b>{{ purchase.eventData.item.itemName }}</b><!--&nbsp;<a :href="purchase.eventData.item.tokenUrl" target="_blank" ><i class="fa fa-link"></i></a>--></span> 
                              <div>
                                <a v-if="purchase.eventData.item.nftData.metadata" :href="getNftUrl(purchase.eventData.item)" target="_blank" style="text-decoration: underline;">NFT<br/></a>
                                <a v-if="purchase.eventData.item.arData.snapchat.url" :href="purchase.eventData.item.arData.snapchat.url" target="_blank" style="text-decoration: underline;">Snapchat<br/></a>
                                <a v-if="purchase.eventData.item.arData.tiktok.url" :href="purchase.eventData.item.arData.tiktok.url" target="_blank" style="text-decoration: underline;">Tiktok<br/></a>
                                <a v-if="purchase.eventData.item.arData.instagram.url" :href="purchase.eventData.item.arData.instagram.url" target="_blank" style="text-decoration: underline;">Instagram<br/></a>
                              </div>
                            </div>
                          </div>
                                                
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="store.userMetadata" class="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div class="flex flex-wrap justify-center">
                    <div class="w-full lg:w-9/12 px-4">
                      <h3 v-if="orderEvents" class="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">Orders</h3>
                      <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                        <br/>
                      <table v-if="orderEvents" align="center" >
                        <thead>
                          <tr>
                            <th>Item</th>
                            <th style="padding-left: 40px" align="left">Event</th>
                            <th style="padding-left: 40px" align="left">Type</th>
                            <th style="padding-left: 40px">Name</th>
                            <th style="padding-left: 40px">Price</th>
                            <th style="padding-left: 40px">Date</th>
                            <th style="padding-left: 40px">Time</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="orderEvent in orderEvents" v-bind:key="orderEvent.id" >
                            <td><img v-if="orderEvent.eventData.item" width="36" :src="orderEvent.eventData.item.nftData.metadata.image" /></td>
                            <td style="padding-left: 40px" align="left">{{ translate(orderEvent.eventType) }}</td>
                            <td style="padding-left: 40px" align="left">{{ orderEvent.eventData.purchaseType }}</td>
                            <td style="padding-left: 40px"><span v-if="orderEvent.eventData.item">{{ orderEvent.eventData.item.itemName }}</span></td>
                            <td style="padding-left: 40px"><span v-if="orderEvent.eventData.item">{{ orderEvent.eventData.item.listingData.buyoutPricePerToken }}</span></td>
                            <td style="padding-left: 40px">{{ new Date(orderEvent.createdDate).toLocaleDateString() }}</td>
                            <td style="padding-left: 40px">{{ new Date(orderEvent.createdDate).toLocaleTimeString() }}</td>
                          </tr>
                        </tbody>
                      </table>
                                
                      </p>
                    </div>
                  </div>
                </div>
                <div v-if="store.userMetadata" class="mt-10 py-10 border-t border-blueGray-200 text-center">
                  <div class="flex flex-wrap justify-center">
                    <div class="w-full lg:w-9/12 px-4">
                      <h3 class="text-2xl font-semibold leading-normal mb-2 text-blueGray-700 mb-2">Connected wallets</h3>
                      <p class="mb-4 text-lg leading-relaxed text-blueGray-700">
                      <br/>
                      <table align="center" style="border: 1px">
                        <thead>
                          <tr>
                          <th>Address</th>
                          <th>Type</th>
                        </tr>
                        </thead>
                        <tr  v-for="wallet in store.userMetadata.wallets" v-bind:key="wallet.publicKey">
                          <td><a :href="getWalletExplorerUrl(wallet)" target="_blank" style="text-decoration: underline;">{{ wallet.publicKey }}</a> </td>
                          <td>{{ wallet.type }}</td>
                        </tr>
                      </table>
                      </p>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";

import progress from "@/assets/img/progress-sm.gif";
import UserService from "../services/UserService";
import { store } from '../store'
import { AuthenticatedApi} from "@/services/Api.js";
import EventTypes from "../services/EventTypes";
import { Environment } from '@/services/Environment.js';

export default {
  data() {
    return {
      profileImgSrc: null,
      user: null,
      email: null,
      name: null,
      progress,
      store,
      orderEvents: null,
      purchases: null,
      isTransferPending: false,
      items: null,
      settings: null
    };
  },
  components: {
    Navbar,
    FooterComponent,
  },
  computed: {
    heroDivStyle() {
      if (!this.settings) {
        return "";
      }
      return `background-image: url('${this.settings.images.heroUrl}');`
    }
  },
  methods: {
    translate(eventType) {
      try {
        switch (eventType) {
          case EventTypes.PURCHASE_INITIATED:
            return "Order Started";
          case EventTypes.PURCHASE_FAILED:
            return "Order Failed";
          case EventTypes.PURCHASE_SUCCEEDED:
            return "Order Completed";
          default:
            //snake_case to Title Case
            return eventType
              .split("_")
              .filter(x => x.length > 0)
              .map((x) => (x.charAt(0).toUpperCase() + x.slice(1)))
              .join(" ");
          }        
        } catch (e) {
        console.error("here");
        console.error(e);
      }
    },
    findItem(itemId) {
      return this.items.find(i => i.itemId == itemId);
    },
    decorate(events) {
      for (let event of events) {
        let itemId = event.eventData.itemId;
        if (itemId) {
          const item = this.findItem(itemId);
          if (!item) {
            console.warn(`${itemId} not found`);
            console.log(this.items);
          }
          event.eventData.item = item;
        }
      }
      return events;
    },
    getNftUrl(item) {
      return `https://opensea.io/assets/matic/${item.collectionAddress}/${item.nftData.metadata.id}`;
    },
    getWalletExplorerUrl(wallet) {
      return `https://app.zerion.io/${wallet.publicKey}/nfts`;
    },
    loginOrSignup() {
      let environment = new Environment();
      let loginUrl = environment.loginUrl
      //TODO: whitelist the state to return with... e.g. don't want jwt= if it's still there. other things?
      window.location.href  =`${loginUrl}?returnTo=${window.location.origin}`;
    },
    delayedCheckTransferStatus() {
      //Check back in 5 seconds and see if if it's still pending
      console.log("Will check for transfer status in 5 seconds...");
      setTimeout(async () => {
          const api = new AuthenticatedApi(this.$cookies);
          console.log("Checking if transfer has completed")
          const resOrderEventsNew = await api.get(`/api/user/events?eventTypes=${EventTypes.PURCHASE_INITIATED},${EventTypes.PURCHASE_SUCCEEDED},${EventTypes.PURCHASE_FAILED},${EventTypes.PAYMENT_RECEIVED},${EventTypes.TRANSFER_PENDING}`);
          let orderEventsNew = resOrderEventsNew.data;
          if (orderEventsNew && orderEventsNew.length > 0) {
            const lastEventNew = orderEventsNew.at(0);
            if (lastEventNew.eventType != EventTypes.TRANSFER_PENDING) {
              //TODO: do better... reload the state of the page, not the whole page
              console.log("Done. Reloading...");
              window.location.reload();
            } else {
              console.log("Still pending...");
              this.delayedCheckTransferStatus();
            }
          }          
        }, 5000);
    }
  },
  async mounted() {

    //Profile picture (TODO: move to store)
    let userService = new UserService();
    const user = await userService.getUser();
    if (user) {
      this.user = user;
      this.name = `${user.name.first_name} ${user.name.last_name}`;
      if (user.emails && user.emails.length > 0) {
        this.email = user.emails[0].email;
      }
      if (user.providers && user.providers.length > 0) {
        this.profileImgSrc = user.providers[0].profile_picture_url;
      }
    } else {
      console.log("No stytch user");
      return;
    }

    //Order related events
    const api = new AuthenticatedApi(this.$cookies);
    const resPurchases = await api.get(`/api/user/events?eventTypes=${EventTypes.PURCHASE_SUCCEEDED}`);
    let purchases = resPurchases.data;
    purchases = resPurchases.data.filter(event => event.eventData.itemId); //tmp: filter out old bad data
    const resOrderEvents = await api.get(`/api/user/events?eventTypes=${EventTypes.PURCHASE_INITIATED},${EventTypes.PURCHASE_SUCCEEDED},${EventTypes.PURCHASE_FAILED},${EventTypes.PAYMENT_RECEIVED},${EventTypes.TRANSFER_PENDING}`);
    let orderEvents = resOrderEvents.data;
    if (orderEvents && orderEvents.length > 0) {
      const lastEvent = orderEvents.at(0);
      console.log("last event was:")
      console.log(lastEvent);
      this.isTransferPending = (lastEvent.eventType == EventTypes.TRANSFER_PENDING);
      if (this.isTransferPending) {
        this.delayedCheckTransferStatus();
      }
    }

    //Decorate events with item details
    const purchaseItemIds = purchases.filter(event => event.eventData.itemId).map(event => event.eventData.itemId);
    const orderItemIds = orderEvents.filter(event => event.eventData.itemId).map(event => event.eventData.itemId);
    
    //Get the items to decorate events with
    //Concat and de-dupe
    const itemIds = [... new Set([...purchaseItemIds, ...orderItemIds])];
    const itemIdsStr = itemIds.join(",");
    const resItems = await api.get(`/api/items?ids=${itemIdsStr}`);
    this.items = resItems.data;
    console.log("items:", this.items)

    //Decorate events with the item they are related to    
    this.purchases = this.decorate(purchases);
    this.orderEvents = this.decorate(orderEvents);
    console.log("purchases:", this.purchases);

    const resSettings = await api.get("/api/public/settings");
    this.settings = resSettings.data;
  }
};
</script>
