//import { createApp } from "vue";
import * as Vue from 'vue'
import { createWebHistory, createRouter } from "vue-router";
import VueCookies from 'vue-cookies'

// styles
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";

// mouting point for the whole app
import App from "@/App.vue";

// views without layouts
import Site from "@/views/Site.vue";
import Login from "@/views/Login.vue";
import Account from "@/views/Account.vue";

// routes
console.log(`ENV:${process.env.NODE_ENV}`); 

const routes = [   
  {
    path: "/login",
    component: Login
  },
  {
    path: "/account",
    component: Account
  },
  {
    path: "/",
    component: Site,
  },
  { path: "/:pathMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

Vue.createApp(App)
  .use(VueCookies, { expires: '7d'})
  .use(router)
  .mount("#app");