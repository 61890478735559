export default class BlockchainExplorerService {
    constructor(blockchain) {
        console.log(`ctor: BlockchainExplorerService(${blockchain})`);
        this.blockchain = blockchain
        this.polygonBase = "https://polygonscan.com"
        this.mumbaiBase = "https://mumbai.polygonscan.com"
        this.ethereumBase = "https://etherscan.io"
        this.defaultBase = "https://blockscan.com"
    }

    getAddressUrl(address) {
        switch(this.blockchain) {
            case "polygon":
                return `${this.polygonBase}/address/${address}`;
            case "mumbai":
                return `${this.mumbaiBase}/address/${address}`;
            case "ethereum":
                return `${this.ethereumBase}/address/${address}`;
            default:
                return `${this.defaultBase}/address/${address}`;
        }
    }

    getTransactionUrl(txnHash) {
        switch(this.blockchain) {
            case "polygon":
                return `${this.polygonBase}/tx/${txnHash}`;
            case "mumbai":
                return `${this.mumbaiBase}/tx/${txnHash}`;
            case "ethereum":
                return `${this.ethereumBase}/tx/${txnHash}`;
            default:
                return `${this.defaultBase}/tx/${txnHash}`;
        }
    }

    getTokenUrl(address, id) {
        switch(this.blockchain) {
            case "polygon":
                return id == null ? `${this.polygonBase}/token/${address}` : `${this.polygonBase}/token/${address}?a=${id}`;
            case "mumbai":
                return id == null ? `${this.mumbaiBase}/token/${address}` : `${this.mumbaiBase}/token/${address}?a=${id}`;
            case "ethereum":
                return id == null ? `${this.ethereumBase}/token/${address}` : `${this.ethereumBase}/token/${address}?a=${id}`;
            default:
                return id == null ? `${this.defaultBase}/token/${address}` : `${this.defaultBase}/token/${address}?a=${id}`;
        }
    }
}