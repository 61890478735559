import { ethers } from "ethers";
const erc20Abi = require('./ERC20Abi.json');
export default class WalletService {
    constructor() {
        
    }

    async loadAccount() {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let accounts = await provider.listAccounts();
        console.log("accounts:", accounts);
        if (accounts && accounts.length > 0) {
          return accounts[0];
        }
        return null;
    }

    async connectAccount() {
        //console.log("connect: userInitiated=", userInitiated);
        if (!window.ethereum) {
          if (confirm("You need a digital wallet to purchase NFTs. Click OK to download.")) {
            window.open("https://metamask.io/download/", "_blank")
          }
          return false;
        }

        const provider = new ethers.providers.Web3Provider(window.ethereum);
        await provider.send("eth_requestAccounts", []);
        console.log(await provider.getNetwork());
        const signer = provider.getSigner();
        let account = await signer.getAddress(); 
        return account;
      }  
      
      async getUsdcBalance(account) {
        console.log(`Getting USDC balance for ${account}`);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract("0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174", erc20Abi, provider);
        const walletBalance = await contract.balanceOf(account);
        //console.log(contract.decimals()); //==6
        console.log(walletBalance);
        console.log(walletBalance / 10**6)
        return walletBalance / 10**6
      }
}