<template>
    <div class="container flex content-center items-center justify-center mt-12 mx-auto">
        <div class="mx-4 ">
        <img class="rounded-lg" :src="hero" />
      </div>
      
      <div class=" ">
          <div id="stytch-sdk"></div>
          <div v-if="token">Logging in...</div>
      </div>
    </div>
  </template>
<script>
 import { StytchUIClient } from '@stytch/vanilla-js';
import hero from "@/assets/img/datelier-blue.jpg";
//import Api from "@/services/Api.js";
import {Environment} from '@/services/Environment.js';
export default {
  data() {
    return {
      hero,
      token: null,
      tokenType: null,
      returnTo: null,
      redirect: null,
    };
  },
  async mounted() {    

    //Scenario 1: Page load from d-atelier.xyz (own marketplace) TBD
    //Scenario 2: Page load from brand, e.g. nfts.meshika.com, i.e. accounts.d-atelier.xyz?returnTo=nfts.meshika.com
    //Scenario 3: Page load on redirect from stych after login, i.e. accounts.d-atelier.xyz?token=xyz&redirect=nfts.meshika.com

    //TODO: redirect back to where we came from

    const params = new URLSearchParams(window.location.search);
    this.tokenType = params.get("stytch_token_type");
    this.returnTo = params.get('returnTo');
    this.token = params.get('token');
    this.redirect = params.get('redirect');

    let environment = new Environment();
    let stytchToken = environment.stytchToken;
    const stytch = new StytchUIClient(stytchToken);

    //Scenario 2
    if (this.returnTo) {
        //Load the login widget and embed the returnTo param into the redirectUri
        let redirectUri = `${environment.redirectUriBase}${this.returnTo}`;
        console.log("redirectUri: " + redirectUri);

        const styles = {
            fontFamily: '"Helvetica New", Helvetica, sans-serif',
            width: '321px',
            primaryColor: '#0577CA',
        };

        stytch.mountLogin({
            elementId: '#stytch-sdk',
            styles,
            config: {
                products: ['emailMagicLinks', 'oauth'],
                emailMagicLinksOptions: {
                    loginRedirectURL: redirectUri,
                    loginExpirationMinutes: 30,
                    signupRedirectURL: redirectUri,
                    signupExpirationMinutes: 30,
                    createUserAsPending: true,
                },
                oauthOptions: {
                    providers: [{type: 'google', position: 'floating'}],
                    loginRedirectURL: redirectUri,
                    signupRedirectURL: redirectUri
                },
            },
        });    
    }    
    
    //Scenario 3
    //Redirected back here from either magic link or google oath, with a token that can be exchanged for jwt
    if (this.token) {
      try {
        //Exchange the token from either OAuth or a Magic Link for a JWT
        let res = null;
        console.log(this.tokenType);
        if (this.tokenType == "oauth") {
          //60mins x 24 hrs x 7 days = 10080;
          res = await stytch.oauth.authenticate(this.token, {session_duration_minutes: 10080});
        } else {
          res = await stytch.magicLinks.authenticate(this.token, {session_duration_minutes: 10080});          
        } 
        console.log(JSON.stringify(res, null, 2));
        let jwt = res.session_jwt;
        let sessionToken = res.session_token;

        //Pass the JWT back to the brand site for authenticated calls
        let fullRedirectUrl = `${this.redirect}?jwt=${jwt}&sessionToken=${sessionToken}`;
        console.log(`Redirecting to ${fullRedirectUrl}`);
        window.location.href = fullRedirectUrl;
      } catch (e) {
        console.error(e);
        alert("Login failed. See console.");
      }
      return;
    }    
  }
};
  </script>
  