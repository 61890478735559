import axios from 'axios';
import {Environment, Env} from './Environment';
class PublicApi {
    constructor() {
        let environment = new Environment();
        if (environment.env == Env.DEV) {
            this.apiBase = "http://datelier-local:8787"
        } else {
            this.apiBase = "https://d-atelier.xyz"
        }
        console.log(`apiBase=${this.apiBase}`);
    }
    
    async get(url, conf) {
        let fullUrl = this.getFullUrl(url);  
        return axios.get(fullUrl, conf)
    }

    async post(url, data, conf) {
        let fullUrl = this.getFullUrl(url);     
        return axios.post(fullUrl, data, conf);
    }

    getFullUrl(relativeUrl) {
        return `${this.apiBase}${relativeUrl}`;
    }
}

class AuthenticatedApi {
    constructor(cookies) {
        let environment = new Environment();
        if (environment.env == Env.DEV) {
            this.apiBase = "http://datelier-local:8787"
        } else {
            this.apiBase = "https://d-atelier.xyz"
        }
        const sessionJwt = cookies.get("stytch_session_jwt")
        this._sessionJwt = sessionJwt;
        console.log(`apiBase=${this.apiBase}`);
        console.log(`sessionJwt: ${this.sessionJwt.substring(0,10)}...`);
        axios.defaults.headers.common['Authorization'] = `Bearer ${this.sessionJwt}`;
    }

    get sessionJwt() {
        return this._sessionJwt;
    }  
    async get(url, conf) {
        let fullUrl = this.getFullUrl(url);  
        return axios.get(fullUrl, conf)
    }

    async post(url, data, conf) {
        let fullUrl = this.getFullUrl(url);     
        return axios.post(fullUrl, data, conf);
    }

    async put(url, data, conf) {
        let fullUrl = this.getFullUrl(url);     
        return axios.put(fullUrl, data, conf);
    }

    getFullUrl(relativeUrl) {
        return `${this.apiBase}${relativeUrl}`;
    }
}

export {PublicApi, AuthenticatedApi};