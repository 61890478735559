
import { StytchUIClient } from '@stytch/vanilla-js';
import { Environment } from '@/services/Environment.js';
export default class UserService {
    constructor() {
        console.log("ctor: UserService");
    }

    async getUser() {
        let environment = new Environment();
        let stytchToken = environment.stytchToken;
        const stytch = new StytchUIClient(stytchToken);
        //console.log(JSON.stringify(stytch.user, null, 2));

        console.log("Getting session...");
        if (!stytch.session.getSync()) {
            return null;
        }

        console.log("Getting user...");
        const user = await stytch.user.get();
        return user;
    }

    async isLoggedIn() {
        let user = await this.getUser();
        console.log(`isLoggedIn: ${user != null}`);
        return user != null;
    }
}   