<template>
  <div>
    <navbar :logoUrl="settings.images.logoUrl" />
    
    <main>
      <div
        class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75"
      >
        <div
          class="absolute top-0 w-full h-full bg-center bg-cover"
          :style="heroDiv"
        >
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-75 bg-black"
          ></span>
        </div>

        <div class="container relative mx-auto">
          <div class="items-center flex flex-wrap">
            <div class="w-full lg:w-12/12 px-4 ml-auto mr-auto text-center">
              <div class="pr-12">
                <h1 class="text-white font-semibold text-5xl">
                  {{settings.content.title}}
                </h1>
                <p class="mt-4 text-lg text-blueGray-200">
                  {{ settings.content.subtitle}}
                </p>
              </div>
            </div>
          </div>
        </div>  
             
      </div>     

      <section v-if="collection" class="pt-20 pb-20">
        <div class="container mx-auto px-4 text-center">

          <h3 class="text-3xl font-semibold">{{ collection.name }}</h3>
          <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
            {{collection.description }}
          </p>
        </div>        
      </section>

      <!-- loading -->
      <section v-if="items && items.length == 0"  >
        <div class="container mx-auto px-4 text-center">
          No items yet.
        </div>
      </section>

      <section v-if="!items"  class="pt-20 pb-48">
        <div class="container mx-auto px-4 text-center">
          <i class="fas fa-spinner fa-spin"></i> Loading NFTs...
          
        </div>
      </section>    

      <!-- listings -->
      <section v-for="item in items" v-bind:key="item.itemId" class="relative mb-12">
        <div
          class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20 h-20"
          style="transform: translateZ(0);"
        >
          <svg
            class="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              class="text-white fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div class="container mx-auto px-4">
          <div class="items-center flex flex-wrap">
            <div class="w-full md:w-4/12 ml-auto mr-auto px-4">
              <img
                v-if="!item.properties.animationUrl"
                alt="..."
                class="max-w-full rounded-lg shadow-lg"
                :src="item.nftData.metadata.image"
              />
              <video 
                v-if="item.properties.animationUrl"
                
                preload="auto" loop autoplay muted playsinline style="background-color: white;">
                <source :src="item.properties.animationUrl" type="video/mp4">
              Your browser does not support the video tag.
              </video>
              <!-- @mounted="..."
                @ready="..."
                @play="..."
                @pause="..."
                @ended="..."
                @seeking="..." -->

            </div>
            
            <div class="w-full md:w-5/12 ml-auto mr-auto px-4">
              
              <div class="md:pr-12">
                <!-- <div
                  class="text-blueGray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-blueGray-300"
                >
                  <i class="fas fa-rocket text-xl"></i>
                </div> -->
                <h3 class="text-3xl font-semibold">{{item.itemName}}</h3>
                <p class="mt-4 text-sm leading-relaxed text-blueGray-500">
                  {{( new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 2 }).format(item.listingData.buyoutPricePerToken))}}  USD                  
                  <!-- <span class="float-right">1 of {{item.nftData.supply}} in supply</span> -->
                </p>
                <div class="mt-4 text-lg leading-relaxed text-blueGray-500 text-bold w-full inline-block">                  
                  <span class="font-semibold float-left">About the asset</span>
                  <span class="float-right"> <img :src="imgDropdown" v-bind:class="{'rotated': item.descriptionExpanded}" @click="toggleDescription(item)"></span>
                  <div class="text-base font-light clear-both" v-if="item.descriptionExpanded">
                    {{item.itemDescription}}
                  </div>
                  <!-- <div class="mt-2 text-base font-light clear-both" v-if="item.descriptionExpanded">
                    1 of {{item.nftData.supply}} in supply.
                  </div> -->
                </div>
                <div class="mt-4 text-lg leading-relaxed text-blueGray-500 text-bold w-full inline-block">
                  <span class="font-semibold float-left">How to use NFTs</span>
                  <span class="float-right"> <img :src="imgDropdown" v-bind:class="{'rotated': item.usageExpanded}" @click="toggleUsage(item)"></span>
                  <div v-if="item.usageExpanded" class="clear-both">
                    <ul class="list-none mt-6">
                      <li class="py-2">
                        <div class="flex items-center">
                          <div>
                            <span
                              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                            >
                              <i class="fas fa-anchor"></i>
                            </span>
                          </div>
                          <div class="text-base">                            
                              Trade it on Opensea, the largest NFT marketplace.
                          </div>
                        </div>
                      </li>     
                      <li class="py-2">
                        <div class="flex items-center">
                          <div>
                            <span
                              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                            >
                              <i class="fas fa-video"></i>
                            </span>
                          </div>
                          <div class="text-base">                            
                              NFT includes a video (.mp4) and 2D collectibles (.pngs).
                          </div>
                        </div>
                      </li>   
                      <li class="py-2">
                        <div class="flex items-center">
                          <div>
                            <span
                              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                            >
                              <i class="fas fa-link"></i>
                            </span>
                          </div>
                          <div class="text-base">                            
                              Minted on the {{ capitalized(item.blockchain) }} blockchain, 99% less energy-consuming than other blockchains.                            
                          </div>
                        </div>
                      </li>     
                    </ul>
                    



                  </div>
                </div>
                <div class="mt-4 text-lg leading-relaxed text-blueGray-500 text-bold w-full inline-block">
                  <span class="font-semibold float-left">How to use Augmented Reality Effect</span>
                  <span class="float-right"> <img :src="imgDropdown" v-bind:class="{'rotated': item.arExpanded}" @click="toggleAr(item)"></span>
                  <div v-if="item.arExpanded" class="clear-both text-base">
                    <ul class="list-none mt-6">
                      <li class="py-2">
                        <div class="flex items-center">
                          <div>
                            <span
                              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                            >
                              <i class="fab fa-tiktok"></i>
                            </span>
                          </div>
                          <div class="text-base">                            
                            Snap a video or photo using an AR filter to post on TikTok and Instagram and everywhere you live online.
                          </div>
                        </div>
                      </li>     
                      <li class="py-2">
                        <div class="flex items-center">
                          <div>
                            <span
                              class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                            >
                              <i class="fab fa-instagram"></i>
                            </span>
                          </div>
                          <div class="text-base">                            
                            Includes a Snapchat and Instagram lens for wearing your virtual asset in social media. Works for iPhone & Android. 
                          </div>
                        </div>
                      </li>                        
                    </ul>
                  </div>
                </div>
                <!-- <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
                  {{item.itemDescription }}
                </p> -->
               
                <!-- <ul class="list-none mt-6">
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                        >
                          <i class="fas fa-fingerprint"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">
                          Only {{item.nftData.supply}} minted on the {{ item.blockchain }} blockchain <a :href="item.tokenUrl" target="_blank" ><i class="fa fa-link"></i></a>
                        </h4>
                      </div>
                    </div>
                  </li>                
                  <li v-if="item.arData.snapchat.url || item.arData.tiktok.url" class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                        >
                          <i class="fa fa-vr-cardboard"></i>
                        </span>
                      </div>
                      <div >
                        <h4 class="text-blueGray-500">
                          Includes <span v-if="item.arData.snapchat.url" >Snapchat</span> <span v-if="item.arData.tiktok.url"> and TikTok</span> AR filter
                        </h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                        >
                          <i class="fas fa-crown"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">Leading designer Alberto</h4>
                      </div>
                    </div>
                  </li>
                  <li class="py-2">
                    <div class="flex items-center">
                      <div>
                        <span
                          class="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-blueGray-600 bg-blueGray-200 mr-3"
                        >
                          <i class="fas fa-money-bill"></i>
                        </span>
                      </div>
                      <div>
                        <h4 class="text-blueGray-500">Resell on sites like Opensea</h4>
                      </div>
                    </div>
                  </li>
                </ul> -->
              </div>
              <div class="mt-4">
                    <button
                      @click="buy(item.itemId)"
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Buy with crypto
                    </button>
                    <button
                      @click="buyWithCreditCard(item.itemId)"
                      class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                    >
                      Buy with credit card
                    </button>    
                                   
                    <div>
                      <span v-if="item.progressMessage"><i class="fas fa-spinner fa-spin"></i>&nbsp;<i>{{item.progressMessage}}</i></span>
                      <span v-if="item.purchaseSucceeded === true"> <i style="color: green;" class="fas fa-check" aria-hidden="true"></i> {{ item.completionMessage }}</span>
                      <span v-if="item.purchaseSucceeded === false"><i style="color: red;" class="fas fa-bell" aria-hidden="true"></i> {{ item.completionMessage }}</span>
                    </div>
                    
                                     

                    <!-- <div>
                      <div ref="popoverRef" 
                        v-bind:class="{'hidden': !popoverShow, 'block': popoverShow}" 
                        class="bg-blueGray-200 border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
                        style="z-index: 10;  position: absolute; top:20rem; right: 20rem;">
                        
                        <div>
                          <div class="bg-blueGray-200 text-black opacity-25 font-semibold p-3 mx-3 border-b border-solid border-blueGray-100 uppercase rounded-t-lg">
                            Social Media Photo-Realistic Dressing
                          </div>
                          <div class="text-black p-3">                           
                              This item comes with a photo-realistic render of the<br/>
                              item dressed on a photo of you for social media.<br/>
                              <br/>
                              You will upload a photo after purchase.
                              <br/>
                              <br/>
                            <img width="200" :src="dressedSrc" />
                          </div>
                          
                        </div>
                      </div>   

                      <button 
                        @mouseover="popoverShow = true"
                        @mouseleave="popoverShow = false" 
                        class="text-blueGray-500 bg-transparent border border-solid border-blueGray-500 hover:bg-blueGray-500 hover:text-white active:bg-blueGray-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
                        Dress me          
                      </button>
                    </div>   -->
                  </div>
            </div>
          </div>
        </div>
      </section>     
    </main>
    <footer-component />
  </div>
</template>
<script>
import Navbar from "@/components/Navbars/AuthNavbar.vue";
import FooterComponent from "@/components/Footers/Footer.vue";
import { BigNumber, ethers } from "ethers";
import { ThirdwebSDK, CHAIN_ID_TO_NAME } from "@thirdweb-dev/sdk";
// import dressed from "@/assets/img/dressed.jpg";
import { store } from '../store'
import {PublicApi, AuthenticatedApi} from "@/services/Api.js";
import BlockchainExplorerService from "@/services/BlockchainExplorerService";
//import { StytchUIClient } from '@stytch/vanilla-js';
import UserService from "../services/UserService";
import EventTypes from "../services/EventTypes";
import { Environment } from '@/services/Environment.js';
import imgDropdown from "@/assets/img/dropdown.svg";
export default {
  data() {
    return {    
      openTab: 1,
      user: null, //stytch user (session)
      show: false,
      blockchain: null,
      marketplaceAddress: null,
      listings: null,
      items:null,
      collection: null,
      store,
      popoverShow: false,
      /*dressedSrc: dressed,*/
      settings: {images: {}, handles: {}, content: {}},
      imgDropdown     
    };
  },
  computed: {
    heroDiv() {
      if (!this.settings) {
        return "";
      }
      return `background-image: url('${this.settings.images.heroUrl}');`
    }
  },
  methods: {
    async buyWithCreditCard(itemId) {
      if (!this.user) {
        alert("Login or create account to buy an item.");
        this.loginOrSignup();
        return;
      }

      const api = new AuthenticatedApi(this.$cookies);
      let item = null;
      console.log(`crypto buy:${itemId}`);
      item = this.findItem(itemId);
      try {
        if (!item) {
          alert("Item not found")
          return;
        }       
        console.log(JSON.stringify(item, null, 2)); 
        item.purchaseSucceeded = null;
        item.progressMessage = "Preparing credit card transaction...";     

        api.post("/api/user/events", { eventType: EventTypes.PURCHASE_INITIATED, eventData: {
          itemId,
          item, 
          purchaseType: "credit-card"
        }})
        let res = await api.post(`/api/payments/checkout-session`, { item });
        console.log(res.data);
        window.location.href = res.data.sessionUrl;

      } catch (e) {
        console.error(e);
        item.progressMessage = null;
        item.completionMessage = "Purchase failed. Contact D'Atelier support.";
        item.purchaseSucceeded = false;

        api.post("/api/user/events", { eventType: EventTypes.PURCHASE_FAILED, eventData: {
          itemId,
          item, 
          error: e          
        }})
      }

    }, 
    checkWallet(){
      if (!window.ethereum) {
        if (confirm("You need a digital wallet to purchase NFTs. Click OK to download.")) {
          window.open("https://metamask.io/download/", "_blank")
        }
        return false;
      } else {
        return true;
      }
    },
    loginOrSignup() {
      let environment = new Environment();
      let loginUrl = environment.loginUrl
      //TODO: whitelist the state to return with... e.g. don't want jwt= if it's still there. other things?
      window.location.href  =`${loginUrl}?returnTo=${window.location.origin}`;
    },
    async buy(itemId) {
      
      if (!this.checkWallet()) {
        return;
      }

      if (!this.store.account) {
        alert("Click connect");
        return;
      }

      if (!this.user) {
        alert("Login or create account to buy an item.");
        this.loginOrSignup();
        return;
      }

      const api = new AuthenticatedApi(this.$cookies);
      let txn = null;
      console.log(`crypto buy:${itemId}`);
      let item = this.findItem(itemId);
      try {
        if (!item) {
          alert("Item not found")
          return;
        }       
        console.log(JSON.stringify(item, null, 2)); 

        //Check network
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const network = await provider.getNetwork();
        console.log(JSON.stringify(network));
        const connectedBlockchain = CHAIN_ID_TO_NAME[network.chainId];
        //Different names. E.g. ethers returns "maticum" but ThirdWeb expects "mumbai"
        console.log(`Translated ${network.chainId}/${network.name} -> ${connectedBlockchain}`);
        if (item.blockchain !== connectedBlockchain) {
          alert(`The collection is on ${item.blockchain} but your wallet is connected to ${connectedBlockchain}.`);
          return;
        }
        
        const price = item.listingData.buyoutPricePerToken
        if (this.store.usdcBalance < price) {
          alert(`You need at least ${price} USDC in your wallet to purchase. You have ${this.store.usdcBalance}`);
          return;
        }

        api.post("/api/user/events", { eventType: EventTypes.PURCHASE_INITIATED, eventData: {
          itemId,
          item, 
          blockchain: connectedBlockchain,
          purchaseType: "crypto"
        }})

        item.purchaseSucceeded = null;
        item.progressMessage = "Preparing wallet transaction...";     
        console.log(`Buying listing ${itemId} for ${store.account} from ${item.marketplaceAddress} on ${item.blockchain}.`)
        const signer = provider.getSigner();
        const sdk = ThirdwebSDK.fromSigner(signer, item.blockchain);
        const contract = await sdk.getContract(item.marketplaceAddress, "marketplace");

        item.progressMessage = "Sending to wallet...";     

        // Quantity of the asset you want to buy
        const quantityDesired = 1;
        console.log('buyoutListing',BigNumber.from(item.listingData.id).toNumber());
        txn = await contract.buyoutListing(BigNumber.from(item.listingData.id), quantityDesired);
        item.progressMessage = "Transaction submitted. Waiting...";
        console.log(txn);
        item.progressMessage = null;
        item.completionMessage = "Purchase complete! Check your account.";
        item.purchaseSucceeded = true;
        setTimeout(() => window.location.href = "/account", 1000);

        api.post("/api/user/events", { eventType: EventTypes.PURCHASE_SUCCEEDED, eventData: {
          itemId,
          item, 
          blockchain: connectedBlockchain,
          txn 
        }})

      } catch (e) {
        console.error(e);
        item.progressMessage = null;
        item.completionMessage = "Purchase failed. Contact D'Atelier support.";
        item.purchaseSucceeded = false;

        api.post("/api/user/events", { eventType: EventTypes.PURCHASE_FAILED, eventData: {
          itemId,
          item, 
          error: e,
          txn
        }})

      }
      
      

    },
    // findListing(listingId) {
    //   for (let listing of this.listings) {
    //     if (listing.id == listingId) {
    //       return listing;
    //     }
    //   }
    //   return null;
    // },
    findItem(itemId) {
      for (let item of this.items) {
        if (item.itemId == itemId) {
          return item;
        }
      }
      return null;
    },
    decorate(items) {
      for (let item of items) {
        item.progressMessage = null;
        item.completionMessage = null;
        item.purchaseSucceeded = null;

        item.descriptionExpanded = false;
        item.usageExpanded = false;
        item.arExpanded = false;
      }
      return items; 
    },
    toggleDescription(item) {
      item.descriptionExpanded = !item.descriptionExpanded;
    },
    toggleUsage(item) {
      item.usageExpanded = !item.usageExpanded;
    },
    toggleAr(item) {
      item.arExpanded = !item.arExpanded;
    },
    watchNetworkChanges() {
      //handle network workchange
      if (window.ethereum) {
        window.ethereum.on('chainChanged', async (chainId) => {
          console.log('chainChanged (aka networkChanged).', chainId);
          window.location.reload();
        })
      }
    },
    async loadItemsAndSettings() {
      const api = new PublicApi();
      const [resItems, resSettings] = await Promise.all([
        api.get("/api/public/items"),
        api.get("/api/public/settings")
      ]); 
      this.items = resItems.data;
      this.items = this.decorate(this.items);
      if (this.items && this.items.length > 0) {
        const firstItem = this.items[0];
        this.collection = {
          id: firstItem.collectionId,
          symbol: firstItem.symbol,
          name: firstItem.collectionName,
          description: firstItem.collectionDescription
        }
      }
      console.log(this.items);
      this.settings = resSettings.data;
      console.log(this.settings);

            
      //Decorate items
      for (let item of this.items) {
        const blockchainExplorerService = new BlockchainExplorerService(item.blockchain);
        item.tokenUrl = blockchainExplorerService.getTokenUrl(item.collectionAddress, item.nftData.metadata.id);
      }

      //Default to the collection name
      if (!this.settings.content.title) {
        if (this.collection) {
          this.settings.content.title = this.collection.name;
          //this.settings.subtitle= 
        } else {
          this.settings.content.title= "Our NFT Collection"
          //this.settings.subtitle= 
        }
      }
    },
    async loadUser() {
      const userService = new UserService();
      this.user = await userService.getUser();
    },
    capitalized(str) {
      if (!str || str.length == 0) {
        return "";
      }
      const capitalizedFirst = str[0].toUpperCase();
      const rest = str.slice(1);

      return capitalizedFirst + rest;
    },
  },
  components: {
    Navbar,
    FooterComponent
  },
  async mounted() {
      this.watchNetworkChanges();  
      await this.loadItemsAndSettings();
      await this.loadUser();
  }
};
</script>
<style scoped>
.rotated {
  transform: rotate(180deg);
  transition: transform 0.1s;
  transition-timing-function: linear;
}
</style>